<template>
	<v-container>
		<v-row>
			<v-col>
				<h3 class="titlePage">Interacciones Farmacológicas</h3>
			</v-col>
		</v-row>
		<v-btn			
		class="button-alert"
		>
		Crear Alarma
	</v-btn>

	<v-row>
		<v-col>
			<v-card>
				<v-simple-table>
					<thead>
						<th>
							Principio 1
						</th>

						<th>Principio 2</th>

					</thead>
					<tbody>
						<tr>
							<td>

								<v-row>
									<v-col>Descripción Principio:</v-col> <v-col>Levofloxacino 
									</v-col>
								</v-row>
								<v-row>
									<v-col>ATC OMS:</v-col> <v-col>2791A
									</v-col>
								</v-row>
								<v-row>
									<v-col>DESCRIPCIÓN ATC:</v-col> <v-col>2791A
									</v-col>
								</v-row>

								<v-row>
									<v-col>Vía:</v-col> <v-col>Oral</v-col>
								</v-row>
								<v-row>
									<v-col>Vía Sistémica:</v-col> <v-col>SÍ</v-col>
								</v-row>

							</td>

							<td>
								<v-row>
									<v-col>Descripción Principio:</v-col> <v-col>Aceclofenaco
									</v-col>
								</v-row>
								<v-row>
									<v-col>ATC OMS:</v-col> <v-col>2552A
									</v-col>
								</v-row>
								<v-row>
									<v-col>DESCRIPCIÓN ATC:</v-col> <v-col>Aceclofenaco
									</v-col>
								</v-row>

								<v-row>
									<v-col>Vía:</v-col> <v-col>Oral</v-col>
								</v-row>
								<v-row>
									<v-col>Vía Sistémica:</v-col> <v-col>SÍ</v-col>
								</v-row>
							</td>
						</tr>
					</tbody>




				</v-simple-table>	

			</v-card>
			<hr>
			<hr>

			<v-card class="mx-auto">

				<v-col col="6">
					<v-row v-for="(item, i) in datosInteraccion" :key="i">
						<v-col  >{{  item.title }}   </v-col>
						<v-col >{{ item.value }} </v-col>
					</v-row>
					<v-row>
						<v-col>Interacción</v-col>
						<v-col>Puede producirse una marcada disminución del umbral convulsivo cuando se administran de forma concomitante quinolonas con AINE.</v-col>
					</v-row>
					<v-row>
						<v-col>Actuación</v-col>
						<v-col>Las convulsiones son muy poco frecuentes, así que en la mayoría de los pacientes el uso concomitante no debería causar problemas. Al parecer los pacientes con epilepsia o con predisposición a sufrir crisis convulsivas corren un riesgo mayor, por tanto debe evitarse o hacerse un seguimiento minucioso.</v-col>
					</v-row>
				</v-col>

				

			</v-card>
		</v-col>
	</v-row>


</v-container>
</template>
<style></style>
<script>
export default{
	data:()=>({
		datosInteraccion:[
		{
			title:'Código Gravedad',
			value: 'x'

		},
		{
			title:'Descrìpción Gravedad',
			value: 'MODERADA'

		},
		{
			title:'Código Frecuencia',
			value: 'x'

		},
		{
			title:'Descripción Frecuencia',
			value: 'NO DISPONIBLE'

		},
		{
			title:'Código Evidencia',
			value: 'x'

		},
		{
			title:'Descripción Evidencia',
			value: 'TEÓRICA'

		},
		{
			title:'Edad  ',
			value: 'NO PROCEDE'

		},
		{
			title:'Código Acción',
			value: 'x'

		},
		{
			title:'Descripción Acción',
			value: 'MONITORIZAR'

		},
		{
			title:'Código Sugerencia',
			value: 'x'

		},
		{
			title:'Descripción Sugerencia',
			value: 'VIGILAR CLÍNICA, PRECAUCIONES EN POBLACIÓN DE RIESGO'

		},

		]

	})
}
</script>
